<script lang="ts" setup>
import type { HelpCreateTicketReqBody } from "~/server/api/help/createTicket.post";
import type { HelpValidateReqBody } from "~/server/api/help/validate.post";
import confetti from "canvas-confetti";
import Markdown from "~/components/G/Markdown/index.client.vue";
import { useNavigation } from "~/composables/navigation";

const toast = useToast();
const { info } = storeToRefs(useUserStore());

const open = ref(false);
const docsOpen = ref(false);

const { currentWikiContent } = useNavigation();

watch(open, () => {
  resetForm();
});

const urgencyOptions = [
  { label: "Low - Would be nice to have.", value: "5 days" },
  { label: "Medium - Needs to be done but not urgent.", value: "2 days" },
  { label: "High - My work is significantly impacted.", value: "4 hours" },
  { label: "Critical - I cannot perform my job functions.", value: "1 hour" },
];

const message = ref("");
const urgency = ref("");
const validatedTimes = ref(0);
const validationError = ref("");
const validationResponse = ref();
const file = ref<File>();

const attachMissiveConversation = ref(false);
const { selectedConversations: selectedMissiveConversations } = storeToRefs(useMissiveStore());
const missiveConversations = ref<Array<{ subject: string; link: string; }>>([]);
watch(attachMissiveConversation, () => {
  if (!attachMissiveConversation.value) {
    missiveConversations.value = [];
  }
  else {
    if (!selectedMissiveConversations.value.length) {
      toast.add({
        title: "Please select a Missive conversation to attach",
        description: "You can try re-selecting the conversation, if it is already selected.",
        icon: "i-heroicons-exclamation-triangle",
        color: "red",
      });
      setTimeout(() => {
        attachMissiveConversation.value = false;
      }, 1000);
    }
    else {
      missiveConversations.value = selectedMissiveConversations.value.map(f => ({
        subject: f.subject || "Missive Conversation",
        link: f.link,
      }));
    }
  }
}, { immediate: true });

const pending = ref(false);

async function handleSubmit() {
  pending.value = true;

  if (validatedTimes.value < 1) {
    const res = await validateMessage();
    validationResponse.value = res;
    if (res && !res?.valid) {
      validationError.value = res?.message || "";
      pending.value = false;
      return;
    }
  }

  try {
    const name = useUserStore().info?.name;
    const email = useUserStore().info?.email;

    if (!name || !email)
      throw createError("Please refresh the app to login before submitting a help ticket.");

    if (!message.value || !urgency.value)
      throw createError("Please fill out the form before submitting.");

    const formData = new FormData();
    formData.append("metadata", JSON.stringify({
      name: name || "",
      email: email || "",
      department: info?.value?.department || "",
      message: message.value,
      urgency: urgency.value,
      responseTime: urgencyOptions.find(f => f.label === urgency.value)?.value || "",
      missiveConversations: missiveConversations.value,
    } satisfies HelpCreateTicketReqBody));
    if (file.value)
      formData.append("file", file.value);

    await $fetch("/api/help/createTicket", {
      method: "POST",
      body: formData,
    });
    open.value = false;
    toast.add({
      title: "We've got your help ticket!",
      description: `We'll get back to you within ${urgencyOptions.find(f => f.label === urgency.value)?.value} at the latest.`,
      icon: "i-heroicons-check-circle-solid",
    });
    throwConfetti();
  }
  catch (error: any) {
    console.error(">> createTicket error", error);
    toast.add({
      title: "Something went wrong",
      description: error.message || "Please try again.",
      icon: "i-heroicons-exclamation-triangle",
      color: "red",
    });
  }

  pending.value = false;
}

async function validateMessage() {
  try {
    const res = await $fetch("/api/help/validate", {
      method: "POST",
      body: {
        message: message.value,
      } satisfies HelpValidateReqBody,
    });
    validatedTimes.value++;

    return res;
  }
  catch (error) {
    console.error(">> validateMessage error", error);
  }

  validatedTimes.value++;
}

function handleFileChange(e: FileList) {
  if (!e.length)
    return;

  if (e.length > 1) {
    toast.add({
      title: "Please select only one file",
      icon: "i-heroicons-exclamation-triangle",
      color: "red",
    });
  }

  file.value = e[0];
}

function resetForm() {
  message.value = "";
  urgency.value = "";
  attachMissiveConversation.value = false;
  missiveConversations.value = [];
  validatedTimes.value = 0;
  validationError.value = "";
}

function throwConfetti() {
  confetti({
    particleCount: 100,
    spread: 80,
    origin: { y: 0.6 },
  });
}

const isDebugOpen = ref(false);
defineShortcuts({
  meta_d: {
    handler: () => {
      isDebugOpen.value = !isDebugOpen.value;
    },
  },
});
</script>

<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <UTooltip
        v-if="currentWikiContent"
        text="Learn more about this module"
      >
        <UButton
          icon="i-heroicons-question-mark-circle"
          size="xl"
          class="mb-3 scale-100 rounded-full p-2 transition-transform hover:scale-110"
          variant="soft"
          :ui="{
            icon: {
              size: {
                xl: 'size-6',
              },
            },
          }"
          @click.prevent="docsOpen = true"
        />
      </UTooltip>
      <UTooltip text="Create a help ticket">
        <UButton
          icon="i-heroicons-chat-bubble-bottom-center-text-16-solid"
          size="xl"
          class="scale-100 rounded-full p-2 transition-transform hover:scale-110"
          variant="solid"
          :ui="{
            icon: {
              size: {
                xl: 'size-6',
              },
            },
          }"
          @click.prevent="open = true"
        />
      </UTooltip>
    </div>

    <UDashboardModal
      v-model="open"
      title="Let's get you help"
      description="Fill out the form below in English or Turkish, and we'll get back to you."
      :prevent-close="!!message.length"
    >
      <div
        v-auto-animate
        class="space-y-5"
        :class="{
          'pointer-events-none opacity-60': pending,
        }"
      >
        <UTextarea
          v-model="message"
          placeholder="Provide details on your request"
          autoresize
          :rows="5"
          :maxrows="10"
          autofocus
        />

        <UCheckbox
          v-if="message.length"
          v-model="attachMissiveConversation"
          label="Attach selected Missive conversations below"
        >
          <template #help>
            <ul class="list-disc pl-4">
              <li
                v-for="f in selectedMissiveConversations"
                :key="f.id"
              >
                {{ f.subject }}
              </li>
            </ul>
          </template>
        </UCheckbox>

        <UFormGroup
          v-if="message.length"
          help="You can attach a file to your request if you have any."
        >
          <UInput
            type="file"
            :accept="['image/*', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
            @change="handleFileChange"
          />
        </UFormGroup>

        <div
          v-if="!!message"
          v-auto-animate
        >
          <USelectMenu
            v-model="urgency"
            :options="urgencyOptions.map(f => f.label)"
            placeholder="How urgent is this?"
          />
          <GText
            v-if="!!urgency"
            size="xs"
            class="pt-1"
          >
            You will get a response within <b>{{ urgencyOptions.find(f => f.label === urgency)?.value }}</b> at the latest.
          </GText>
        </div>

        <UAlert
          v-if="validationError"
          icon="i-heroicons-exclamation-triangle"
          :title="validationError"
          color="amber"
          variant="soft"
        />

        <div class="pt-2">
          <UButton
            v-if="!!message && !!urgency"
            class="font-semibold"
            label="Submit"
            trailing-icon="i-heroicons-paper-airplane"
            :loading="pending"
            block
            @click.prevent="handleSubmit"
          />

          <UButton
            class="mt-2"
            label="Cancel"
            variant="link"
            size="xs"
            block
            @click.prevent="open = false"
          />
        </div>
      </div>

      <div v-if="isDebugOpen">
        <p>
          Validation Times
        </p>
        <pre>{{ validatedTimes }}</pre>
        <p>
          Validation Error
        </p>
        <pre>{{ validationError }}</pre>
        <p>
          Validation Response
        </p>
        <pre>{{ validationResponse }}</pre>
      </div>
    </UDashboardModal>

    <UModal
      v-model="docsOpen"
      :ui="{
        height: 'h-auto',
      }"
    >
      <UCard>
        <h1 class="pb-8 text-xl font-bold">
          {{ currentWikiContent?.title }}
        </h1>

        <template v-if="currentWikiContent?.purpose">
          <Markdown
            :content="currentWikiContent?.purpose"
            size="sm"
            class="text-sm"
          />
        </template>

        <template v-if="currentWikiContent?.howTo">
          <Markdown
            :content="currentWikiContent?.howTo"
            size="sm"
            class="pt-2 text-sm"
          />
        </template>
      </UCard>
    </UModal>
  </div>
</template>
