import type { GNavItem } from "~/types/navigation";
import { GrapeAppAuthorization } from "@grape-law-firm/grape-common-notion-client";

export function useNavigation() {
  const navItems: GNavItem[] = [
    {
      label: "Timesheets",
      icon: "i-heroicons-clock",
      to: "/timesheets",
      wiki: grapeWiki.timesheets,
    },
    {
      label: "Translator",
      icon: "i-heroicons-language",
      to: "/translator",
      wiki: grapeWiki.translator,
    },
    {
      label: "Missive Helper",
      icon: "i-heroicons-envelope",
      to: "/missive",
      requiredAuthorizations: [GrapeAppAuthorization.MissiveHelper],
      wiki: grapeWiki.missive,
    },
    {
      label: "Mailchimp Sync",
      icon: "i-heroicons-arrow-path",
      to: "/mailchimp",
      requiredAuthorizations: [GrapeAppAuthorization.SyncHubspotLists],
    },
    {
      label: "Balance Follow-Up",
      icon: "i-heroicons-banknotes",
      to: "/balanceFollowUp",
      requiredAuthorizations: [GrapeAppAuthorization.BalanceFollowUp],
      wiki: grapeWiki.balanceFollowUp,
    },
    {
      label: "Visa Bulletin",
      icon: "i-heroicons-document-plus",
      to: "/bulletin",
      requiredAuthorizations: [GrapeAppAuthorization.VisaBulletin],
      wiki: grapeWiki.bulletin,
    },
    {
      label: "Client Ticket",
      icon: "i-heroicons-user",
      to: "/ticket",
      requiredAuthorizations: [GrapeAppAuthorization.ClientTicket],
      wiki: grapeWiki.ticket,
    },
    {
      label: "Search",
      icon: "i-heroicons-magnifying-glass",
      to: "/search",
      requiredAuthorizations: [GrapeAppAuthorization.Search],
      wiki: grapeWiki.search,
    },
    {
      label: "Leaves",
      icon: "i-heroicons-calendar",
      to: "/leaves",
      wiki: grapeWiki.leaves,
    },
  ];

  const missiveNavItems = [...navItems];

  const currentNavItem = computed(() => {
    const route = useRoute();
    return navItems.find(navItem => navItem.to === route.path);
  });

  const currentWikiContent = computed(() => {
    return currentNavItem.value?.wiki;
  });

  return { navItems, missiveNavItems, currentNavItem, currentWikiContent };
}
