<script setup lang="ts">
import { computed } from "vue";
import MarkdownProseA from "./Prose/A.vue";
import MarkdownProseCode from "./Prose/Code.vue";

interface Props {
  content: string;
  size?: "sm" | "md" | "lg";
}

const props = defineProps<Props>();

const colorMode = useColorMode();

const sizeClasses = computed(() => {
  switch (props.size) {
    case "sm":
      return "prose-h1:text-xl prose-h2:text-lg prose-h3:text-base";
    case "md":
      return "prose-h1:text-2xl prose-h2:text-xl prose-h3:text-lg";
    case "lg":
    default:
      return "prose-h1:text-3xl prose-h2:text-2xl prose-h3:text-xl";
  }
});

const baseClasses = [
  "max-w-none",
  "prose",
  "prose-compact",
  "prose-headings:text-gray-900",
  "prose-p:text-gray-800",
  "prose-li:text-gray-800",
  "prose-blockquote:text-gray-700",
  "prose-strong:text-gray-900",
  "prose-table:text-gray-800",
  "dark:prose-headings:text-gray-100",
  "dark:prose-p:text-gray-300",
  "dark:prose-li:text-gray-300",
  "dark:prose-blockquote:text-gray-400",
  "dark:prose-strong:text-gray-100",
  "dark:prose-table:text-gray-300",
];

const proseClasses = computed(() => {
  const classes = [...baseClasses];

  if (colorMode.value === "dark") {
    classes.push("prose-invert");
  }

  return [...baseClasses, sizeClasses.value].join(" ");
});
</script>

<template>
  <article :class="proseClasses">
    <MDC
      :value="props.content"
      :components="{
        a: MarkdownProseA,
        code: MarkdownProseCode,
      }"
    />
  </article>
</template>

<style>
.prose-compact {
  & > :first-child {
    margin-top: -40px !important;
  }
}

.prose {
  @apply prose-a:text-blue-500 prose-a:no-underline hover:prose-a:text-blue-800
         dark:prose-a:text-blue-400 dark:hover:prose-a:text-blue-300;
}
</style>
