import payload_plugin_awyBkvyjYq from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kMkVSsPilw from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Iedc5cJtB1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nHcvzyzb5V from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_7fX2gdwP2m from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NGSZl3kvO8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_O3Iq6rtUmw from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_oIws80q2AE from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Vpp2568g7T from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_LqldmDCkCt from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_X7whXyrIWR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import scrollbars_client_fY2O4P8dDQ from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.29.1_typescript@5.7._6ndfesrbvythsougjbuih47zxq/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_uV0KsdKhJQ from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.29.1_typescript@5.7._6ndfesrbvythsougjbuih47zxq/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_wWnrGXejEl from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.29.1_typescript@5.7._6ndfesrbvythsougjbuih47zxq/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import autoAnimate_client_dEqVGS6XIw from "/vercel/path0/plugins/autoAnimate.client.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  payload_plugin_awyBkvyjYq,
  revive_payload_client_kMkVSsPilw,
  unhead_Iedc5cJtB1,
  router_nHcvzyzb5V,
  payload_client_7fX2gdwP2m,
  navigation_repaint_client_NGSZl3kvO8,
  check_outdated_build_client_O3Iq6rtUmw,
  chunk_reload_client_oIws80q2AE,
  plugin_vue3_Vpp2568g7T,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LqldmDCkCt,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  formkitPlugin_pZqjah0RUG,
  scrollbars_client_fY2O4P8dDQ,
  presets_uV0KsdKhJQ,
  variables_wWnrGXejEl,
  autoAnimate_client_dEqVGS6XIw,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk
]