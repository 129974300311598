import type { ButtonVariant } from "#ui/types";

export enum StopwatchLabel {
  START = "Start",
  PAUSE = "Pause",
  STOP = "Stop",
  CONVERT = "Convert to Timesheet",
}

export enum StopwatchIcon {
  START = "i-heroicons-play-solid",
  PAUSE = "i-heroicons-pause-20-solid",
  STOP = "i-heroicons-stop-solid",
  CONVERT = "i-heroicons-clock-20-solid",
}

export enum StopwatchAction {
  START = "start",
  PAUSE = "pause",
  STOP = "stop",
  CONVERT = "convert",
}

export type StopwatchButtonVariant = Extract<ButtonVariant, "soft" | "solid">;

export interface StopwatchItem {
  type: StopwatchAction;
  icon: StopwatchIcon;
  label: StopwatchLabel;
  variant: StopwatchButtonVariant;
  condition: boolean;
  action: (e?: Event) => void | Promise<void>;
}
