<script setup lang="ts">
import type { StopwatchButtonVariant, StopwatchItem } from "~/types/stopwatch";
import { AnalyticsEvent } from "~/types/analytics";
import { StopwatchAction, StopwatchIcon, StopwatchLabel } from "~/types/stopwatch";

const interval = ref<NodeJS.Timeout | null>(null);
const startTime = ref<number>(0);
const time = ref<number>(0);
const isRunning = ref(false);
const isPaused = ref(false);
const showStopConfirmation = ref(false);

const { $posthog } = useNuxtApp();
const route = useRoute();

function saveState() {
  const state = {
    time: time.value,
    isRunning: isRunning.value,
    isPaused: isPaused.value,
    lastUpdate: Date.now(),
  };
  localStorage.setItem("stopwatch-state", JSON.stringify(state));
}

const debouncedSaveState = useDebounceFn(() => {
  try {
    saveState();
  }
  catch (error) {
    console.error("Failed to save state:", error);
  }
}, 300);

function loadSavedState() {
  try {
    const savedState = JSON.parse(
      localStorage.getItem("stopwatch-state") || "{}",
    ) as { time: number; isPaused: boolean; isRunning: boolean; lastUpdate: number; };

    time.value = savedState.time || 0;
    isPaused.value = savedState.isPaused || false;
    isRunning.value = savedState.isRunning || false;

    if (savedState.time && savedState.isRunning) {
      const lastUpdate = savedState.lastUpdate;
      if (lastUpdate) {
        const timePassed = Math.min(
          Math.floor((Date.now() - lastUpdate) / 1000),
          24 * 60 * 60,
        );
        time.value += timePassed;
      }

      nextTick(() => {
        startTimer();
      });
    }
  }
  catch (error) {
    console.error("Failed to load saved state:", error);
  }
}

onMounted(() => {
  loadSavedState();
  window.addEventListener("beforeunload", debouncedSaveState);
});

// Cleanup
onUnmounted(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
  window.removeEventListener("beforeunload", debouncedSaveState);
});

// Every time the time, isRunning, or isPaused changes, save the state
watch([time, isRunning, isPaused], () => {
  debouncedSaveState();
}, { deep: true });

function startTimer(e?: Event) {
  if (e)
    e.preventDefault();

  if (interval.value) {
    clearInterval(interval.value);
  }

  isRunning.value = true;
  isPaused.value = false;

  nextTick(() => {
    startTime.value = Date.now() - (time.value * 1000);

    interval.value = setInterval(() => {
      const now = Date.now();
      const elapsed = now - startTime.value;
      time.value = Math.floor(elapsed / 1000);

      isRunning.value = true;
    }, 1000);
  });

  $posthog.capture(AnalyticsEvent.StopwatchStarted, {
    user: useUserStore().info?.name,
  });
}

function pauseTimer(e?: Event) {
  if (e)
    e.preventDefault();

  isRunning.value = false;
  isPaused.value = true;

  nextTick(() => {
    if (interval.value) {
      clearInterval(interval.value);
      interval.value = null;
    }
  });

  debouncedSaveState();

  $posthog.capture(AnalyticsEvent.StopwatchPaused, {
    user: useUserStore().info?.name,
  });
}

function stopTimer(e?: Event) {
  if (e)
    e.preventDefault();

  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
  isRunning.value = false;
  isPaused.value = false;
  time.value = 0;
  localStorage.removeItem("stopwatch-state");

  $posthog.capture(AnalyticsEvent.StopwatchStopped, {
    user: useUserStore().info?.name,
  });
}

const formattedTime = computed(() => {
  const hours = Math.floor(time.value / 3600);
  const minutes = Math.floor((time.value % 3600) / 60);
  const seconds = time.value % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
});

async function handleTimesheetClick() {
  const hours = Math.floor(time.value / 3600);
  const minutes = Math.floor((time.value % 3600) / 60);

  if (hours < 0 || minutes < 0 || minutes >= 60) {
    console.error("Invalid time values:", { hours, minutes });
    return;
  }

  const timesheetData = {
    hours,
    minutes,
    timestamp: Date.now(),
    source: "stopwatch",
  };

  if (route.path !== "/timesheets") {
    try {
      localStorage.setItem(
        "stopwatch-timesheet-data",
        JSON.stringify(timesheetData),
      );
    }
    catch (error) {
      console.error("Failed to save timesheet data:", error);
      return;
    }
    await navigateTo("/timesheets");
  }
  else {
    localStorage.setItem("stopwatch-timesheet-data", JSON.stringify(timesheetData));
    window.dispatchEvent(new CustomEvent("timesheet-data-updated"));
  }

  $posthog.capture(AnalyticsEvent.StopwatchToTimesheet, {
    user: useUserStore().info?.name,
    duration: time.value,
    hours,
    minutes,
    source: "stopwatch",
  });
}

function handleStopClick(e?: Event) {
  if (e)
    e.preventDefault();

  showStopConfirmation.value = true;
}

function confirmStop(e?: Event) {
  if (e)
    e.preventDefault();

  stopTimer(e);
  showStopConfirmation.value = false;
}

const buttonStates = computed(() => ({
  running: isRunning.value,
  paused: isPaused.value,
}));

const hasTime = computed(() => time.value > 0);

const stopwatchItems = computed<StopwatchItem[]>(() => {
  const { running, paused } = buttonStates.value;
  const timeExists = hasTime.value;

  return [
    {
      type: StopwatchAction.START,
      icon: StopwatchIcon.START,
      label: StopwatchLabel.START,
      variant: "soft",
      condition: !running,
      action: startTimer,
    },
    {
      type: StopwatchAction.PAUSE,
      icon: StopwatchIcon.PAUSE,
      label: StopwatchLabel.PAUSE,
      variant: "soft",
      condition: running && !paused,
      action: pauseTimer,
    },
    {
      type: StopwatchAction.STOP,
      icon: StopwatchIcon.STOP,
      label: StopwatchLabel.STOP,
      variant: "soft",
      condition: running || paused,
      action: handleStopClick,
    },
    {
      type: StopwatchAction.CONVERT,
      icon: StopwatchIcon.CONVERT,
      label: StopwatchLabel.CONVERT,
      variant: "solid",
      condition: !running && timeExists,
      action: handleTimesheetClick,
    },
  ];
});
</script>

<template>
  <div
    v-auto-animate
    class="flex items-center"
  >
    <div
      v-if="isRunning || isPaused"
      class="text-primary mr-2 min-w-[72px] text-center text-xs font-medium tabular-nums"
    >
      {{ formattedTime }}
    </div>

    <div class="flex items-start gap-0">
      <UButtonGroup
        class="!items-center"
        size="2xs"
      >
        <template
          v-for="(item, index) in stopwatchItems.filter(item => item.condition)"
          :key="item.type"
        >
          <UTooltip :text="item.label">
            <UPopover
              v-if="item.label === StopwatchLabel.STOP"
              v-model:open="showStopConfirmation"
              :popper="{ placement: 'top' }"
            >
              <UButton
                :variant="item.variant as StopwatchButtonVariant"
                :icon="item.icon"
                color="primary"
                class="border-primary-300 flex !h-[22px] !w-[24px] items-center justify-center border"
                :class="[
                  index === 0 && '!rounded-l-md !rounded-r-none',
                  index === stopwatchItems.filter(item => item.condition).length - 1 && '!rounded-l-none !rounded-r-md',
                  index > 0 && index < stopwatchItems.filter(item => item.condition).length - 1 && '!rounded-none',
                ]"
                :ui="{
                  padding: { '2xs': 'px-1.5 py-1.5' },
                  icon: { size: { '2xs': 'w-3 h-3' } },
                }"
                @click="item.action"
              />

              <template #panel>
                <div class="space-y-2 p-2">
                  <p class="text-xs">
                    Discard current time?
                  </p>
                  <UButton
                    size="2xs"
                    color="red"
                    class="!rounded-md"
                    block
                    @click="confirmStop"
                  >
                    Discard
                  </UButton>
                  <UButton
                    size="2xs"
                    variant="ghost"
                    color="gray"
                    :padded="false"
                    block
                    @click="showStopConfirmation = false"
                  >
                    Cancel
                  </UButton>
                </div>
              </template>
            </UPopover>

            <UButton
              v-else
              :variant="item.variant as StopwatchButtonVariant"
              size="2xs"
              :icon="item.icon"
              color="primary"
              class="border-primary-300 flex !h-[22px] !w-[24px] items-center justify-center border"
              :class="[
                index === 0 && '!rounded-l-md !rounded-r-none',
                index === stopwatchItems.filter(item => item.condition).length - 1 && '!rounded-r-md',
                index > 0 && index < stopwatchItems.filter(item => item.condition).length - 1 && '!rounded-none',
              ]"
              :ui="{
                padding: { '2xs': 'px-1.5 py-1.5' },
                icon: { size: { '2xs': 'w-3 h-3' } },
                base: 'h-full',
              }"
              @click="item.action"
            />
          </UTooltip>
        </template>
      </UButtonGroup>
    </div>
  </div>
</template>
