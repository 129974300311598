import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_@upstash+redis@1.34.3_@vercel+blob@0.27_42f24d2ittnu3omsmg63pnyxei/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.29.1_typescript@5.7._6ndfesrbvythsougjbuih47zxq/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}