import type { GrapeWiki } from "../types/wiki";

export const grapeWiki: GrapeWiki = {
  timesheets: {
    title: "⏱️ Timesheet",
    purpose: `### Purpose

The Timesheet module is your go-to place to keep track of how you spend your time at work. See [Company Handbook](https://www.notion.so/grapelaw/Employment-Basics-3b4e5bc9decf4181927a8014b5eb3e91?pvs=4#c952f81e136b4cda949f58a166d0b138) for detailed information.`,
    howTo: `### How To

1. Select Date on Timesheet Calendar
* The calendar shows your work history with a handy color-coded system based on your entries for the day
* Click on any day to view or edit past entries

2. Create Timesheet Entry
* Once you selected a date on the calendar, you need to fill in the form and submit it to create an entry
* **Time**: Enter how long you worked for the given task
* **Project/Case**: Choose the relevant project or case from the dropdown
* **Description**: Briefly describe what you did
* Click "Submit" to save your entry

3. Review Existing Entries
* All your timesheet entries for the selected day will appear as a list below the timesheet form
* Feel free to edit or delete your existing entries as needed

> **💡 Bonus Tip**: We have a built-in stopwatch at the top of the app. You can use it to start a timer and then convert the time recorded to a timesheet with a single click.`,
  },

  translator: {
    title: "🌐  Translator",
    purpose: `### Purpose

The Translator module allows you to quickly and easily translate your PDF and Word documents between languages. The translator is able to do OCR (so you can upload images) and should be able to keep the format of your documents consistent.`,
    howTo: `### How To

1. Upload Documents
* Drag and drop your file or click "Click to Browse" to upload a document.

2. Choose Languages
* From: Select the document's original language (or use "Auto" to let the system detect it automatically).
* To: Select the language you want to translate the document into.

3. Start Translation
* Click "Translate" and download your translated document afterwards. Remember that translation can take a couple of minutes depending on the length of your documents.

> **💡 Bonus Tip**: For general AI chat, check out the "Lobe Chat" link!`,
  },

  missive: {
    title: "📨 Missive Helper",
    purpose: `### Purpose

The Missive Helper module integrates client information from Hubspot and Notion directly into Missive. It:

* Automatically detects contacts in selected conversations
* Retrieves relevant Hubspot and Notion cards
* Provides instant access to client information
* Streamlines customer communication workflow`,
    howTo: `### How To

1. Activate Missive Helper
* Click the "Missive Helper" button in the Grapea App navigation bar

2. Select a Conversation
* Select a conversation and wait for Missive Helper to retrieve the contacts

3. View Information Cards
* Click on Hubspot or Notion to see the selected contact's information

> **💡 Pro Tip**: Configure your default views in settings! You can set which cards automatically open when selecting a conversation - perfect for role-specific needs (e.g., Hubspot for sales team).`,
  },

  search: {
    title: "🔍 Search",
    purpose: `## Purpose

The Search module helps you quickly find contact information and access records on Notion or Hubspot by searching with name, surname, phone number, or email of a contact.`,
    howTo: `## How To

1. Input Search Data
* In the "Contact Search" field, type the name, surname, email, or phone number of the person you're looking for.

2. View Results
* Results will show the details of the contacts found. You can click on the Notion or Hubspot icon to open the contact inside that app.`,
  },

  leaves: {
    title: "🌴 Leaves",
    purpose: `### Purpose

The Leaves module simplifies the process of requesting and managing your leaves, including viewing your leave balance, submitting new requests, and seeing your leave history.`,
    howTo: `### How To

1. Check Your Balance
* See your remaining leaves at the top
* Click "Show Calculation Logs" if you'd like to see the details on how your balance was calculated

2. Create New Request
* Under "New Leave Request", fill in the form and send it for approval. Once your request is approved or denied, you will receive an email notification about it

3. Review Your Leave History
* See your leave history under "Used Leaves"`,
  },

  bulletin: {
    title: "🛂 Visa Bulletin",
    purpose: `### Purpose

You can manage and automate visa bulletin updates for cases based on priority dates and case types. Updates will add comments to Notion projects and send email notifications to the team inbox.`,
    howTo: `### How To

1. Select Bulletin Period
* Select the month and year for a specific visa bulletin

2. Configure Case Settings
* Add case type and priority date pairs to it
* Click "Save" to save the pairs to the selected date

3. Run Updates
* At any time, select the month and year and then click "Run" to send updates to the cases in Notion and the team inbox for cases that match the priority dates you've entered`,
  },

  balanceFollowUp: {
    title: "💰 Balance Follow-up",
    purpose: `### Purpose

The Balance Follow-up module helps you request and track balance checks for cases. It collects essential information about the case including family members, application types, and various fees to help calculate the final balance.`,
    howTo: `### How To

1. Select the case

2. Fill in the required information

3. Submit the request. You will receive a notification in Notion when there are updates on your request`,
  },

  ticket: {
    title: "🎫 Client Ticket",
    purpose: `### Purpose

The Ticket module allows you to create and submit client-related tickets for requests, complaints, or feedback. This helps streamline client communication and issue tracking.`,
    howTo: `### How To

1. Select Ticket Type
* Choose from three types:
  * 📝 Request - For general client requests
  * ⚠️ Complaint - For handling client complaints
  * 😊 Feedback - For client feedback submissions

2. Select Related Case
* Search and select the case this ticket is related to
* Type at least 3 characters to search for cases

3. Add Details
* Provide a detailed description of the request, complaint, or feedback
* Mark the ticket as urgent if needed using the toggle

4. Submit Ticket
* Click "Create Ticket" to submit
* You will receive resolution details via email`,
  },
} as const;
